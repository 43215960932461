import styled from "styled-components"

export const GlobalWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const SectionWrapper = styled.div`
padding: 0 20px;
@media screen and (min-width: ${(props) => props.theme.breakpoints.tablet_small}) {
    padding: 0;
  }
`